.become-volunteer
  z-index: 2
  .wrap-circle
    background-color: var(--blue)
    height: 90px
    width: 90px
    padding: 20px
    border-radius: 50%
  button
    border: none
    outline: none
    transition: opacity
    background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem
  .content
    min-height: 20vh
    h1
      padding-bottom: 2vh
      text-transform: uppercase
      opacity: 0.8
    h2, h3, h4
      padding-top: 1vh
      color: var(--red)
    p
      text-align: justify
      line-height: 1.5
      font-weight: 400
    .text-align-right
      text-align: right
    strong
      font-family: 'Marck Script', sans-serif
      line-height: 1
      font-size: calc(1.2vmin + 16px)
      font-weight: 400
      color: var(--blue-dark3)
  .text-align-center
    text-align: center
  .text-align-left
    text-align: left
  .text-align-justify
    text-align: justify
  .text-align-right
    text-align: right
  blockquote, p, li, em
    font-size: calc(0.5vmin + 11px)
    text-align: justify
    font-weight: 400
    line-height: 1.6

