.section-slogan
  position: relative
  min-height: 550px
  -webkit-box-shadow: inset 0 0 40px rgba(0,0,0,0.35)
  box-shadow: inset 0 0 40px rgba(0,0,0,0.35)
  z-index: 2
  .section-text-slogan
    .content.cms-content:after, .content.cms-content:before
      content: " "
      display: table
    .content.cms-content:after
      clear: both
    .content
      position: absolute
      top: 50%
      width: 100%
      -ms-transform: translateY(-50%)
      -webkit-transform: translateY(-50%)
      transform: translateY(-50%)
      padding: 0 1em
      h4
        color: var(--blue-dark)
        font-size: calc(9vmin + 14px)
        font-weight: 400
        line-height: 1
        margin: 0
        opacity: 1
        strong
          color: var(--blue)
          font-weight: 600
  .fixed-img-container
    position: absolute
    z-index: -1
    top: -25vh
    left: 0
    width: 100%
    height: 500%
    overflow: hidden
    .fixed-img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 500%
      background-size: 100% auto
      background-position: top left
      background-repeat: repeat-y

@media (max-width: 375px)
  .section-slogan
    min-height: 50vh
