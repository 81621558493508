.user-registration
  .MuiInput-root, .MuiFilledInput-root, .MuiFilledInput-root
    width: 230px
  .MuiFormHelperText-contained 
    margin-left: unset
    margin-right: unset
  .MuiFormHelperText-root
    color: #f44336
    width: 230px
  .MuiLinearProgress-barColorPrimary
    background-color: var(--orange-dark)
  .MuiFormHelperText-root.Mui-error
    line-height: 14px
  .hide
    display: none
  .show
    display: block
    width: 100%
  .message
    line-height: 1.3
    font-weight: 300
    font-size: 13px
    text-align: left
  .content
    position: relative
    min-height: 20vh
    .MuiButton-containedPrimary
      background-color: var(--blue2)
      margin: 10px 0
    .MuiFormLabel-root.Mui-focused
      color: var(--blue2)
    .MuiInput-underline:after
      border-bottom: 2px solid var(--blue2)
      box-shadow: 0 0 8px 8px white inset
    .MuiFormControl-root
      margin: 5px
    .MuiIconButton-colorPrimary
      color: var(--orange-dark)
    .shadow
      background: url('../../../assets/image/backg-user-reg.jpg') no-repeat
      width: 100%
      height: 140px
      background-size: cover
      background-position: center center
      margin-bottom: 25px
      img
        box-shadow: 0 0 8px 8px white inset
    .consent
      font-size: 14px
    .avatar
      display: flex
      align-items: center
      justify-content: center
      svg
        border-radius: 50%
        width: 100px
        height: 100px
        border: 2px solid
        color: var(--blue)
      img
        width: 100px
        height: 100px
        object-fit: cover
        object-position: top
        border-radius: 50%
    .photo-icon-wrap
      span.MuiIconButton-root
          padding: 5px 0
    .photo-icon
      width: 30px
      height: auto
    .MuiCheckbox-colorPrimary.Mui-checked
      color: var(--blue2)
    .MuiFilledInput-underline:after
      border-bottom: 2px solid var(--blue2)
    .visible-icon
      position: absolute
      margin: 25px 0 0 -32px
      cursor: pointer
    .visible-icon.show
      color: var(--orange-dark)
    .visible-icon.hide
      color: grey


@media (max-width: 768px)
  .user-registration
    .MuiFormControl-root, .MuiInputBase-root 
      width: 100%

@media (max-width: 342px)
  .user-registration
    .MuiButtonBase-root
      width: 150px
    .btn-delete
      margin-left: 0px !important
