.video-section.element-in-view-trigger.in-view > video
  width: 100%
  object-fit: cover
  -o-object-position: center
  object-position: center
  top: 0
  left: 0
  height: 100%
  z-index: 2
.video-section
  position: relative
  z-index: 1
  top: 0
  left: 0
  width: 100%
  height: 0
  min-height: 500px !important
  background-color: var(--blue)
  .video-content
    position: absolute
    overflow: hidden
    text-align: center
    top: 40%
    left: 50%
    width: fit-content
    padding: 0 1em
    max-width: 100%
    -ms-transform: translateY(-40%) translateX(-50%)
    -webkit-transform: translateY(-40%) translateX(-50%)
    transform: translateY(-40%) translateX(-50%)
    z-index: 5
    h1
      color: #fff
      margin: 0
      line-height: 1.25
      -webkit-transition: -webkit-all 0.5s ease
      -webkit-transition: all 0.5s ease
      -o-transition: all 0.5s ease
      transition: all 0.5s ease
    .title
      display: block
      font-size: 42px
      letter-spacing: 0.7px
      padding-bottom: 0.2em
    .subtitle
      display: block
      font-size: 0.466em
      letter-spacing: 2px
    div.title2
      .group-btn
        display: flex
        justify-content: center
        .button
          margin-right: 5px

      h1
        font-size: 3.9em
        letter-spacing: 3px
        text-transform: uppercase
    .colorbar
      position: relative
      width: 100%
      height: 5px
      margin: 1.5em auto
      max-width: 225px
      -webkit-transition: -webkit-all 0.5s ease
      -webkit-transition: all 0.5s ease
      -o-transition: all 0.5s ease
      transition: all 0.5s
      span::after, span::before
        content: ""
        height: 100%
        width: 50%
        position: absolute
        top: 0
        left: 50%
        background-color: var(--blue-dark)
      span::after
        background-color: var(--blue-light)
        left: 25%
    .colorbar::after, .colorbar::before
      content: ""
      height: 100%
      width: 25%
      position: absolute
      top: 0
      left: 0
      background-color: var(--yellow)
    .colorbar::after
      background-color: var(--orange)
      left: 25%
    p
      color: var(--white)
      font-weight: 400
      line-height: 1.75
      margin: 0
      -webkit-transition: -webkit-all 0.5s ease
      -webkit-transition: all 0.5s ease
      -o-transition: all 0.5s ease
      transition: all 0.5s ease
      .button
        margin-top: 1em
        display: inline-block
        padding: 1em 1.75em
        background-color: var(--blue)
        color: var(--white) !important
        line-height: 1.1
        vertical-align: middle
        font-size: 1.2em
        font-weight: 500!important
        font-family: inherit
        text-align: center
        text-decoration: none!important
        border: none
        -webkit-appearance: none
        outline: none
        cursor: pointer
        -webkit-transition: -webkit-background-color 0.35s ease
        -webkit-transition: background-color 0.35s ease
        -o-transition: background-color 0.35s ease
        transition: background-color 0.35s ease
        text-transform: uppercase
      .button.color-d
        background-color: var(--orange)
      .button.color-d:hover
        background-color: var(--orange-dark)
  .video.loaded
    -webkit-animation: video_in 1.5s linear 0.5s forwards
    animation: video_in 1.5s linear 0.5s forwards

@media screen and (min-width: 873px) and (max-width: 1024px)
  .video-section .video-content div.title2 h1
    font-size: 55px

@media screen and (min-width: 768px) and (max-width: 1024px)

@media screen and (max-width: 991px)
  .video-section
    .video-content
      .title
        font-size: 31.5px
      .subtitle
        font-size: 16px
      div.title2 h1
        font-size: 45px

/* mobile */
@media screen and (max-width: 768px)
  .video-section
    .video-content
      .title
        font-size: 30px
      .subtitle
        font-size: 15px
      div.title2
        .group-btn
          flex-direction: column
          .button
            width: 291px
        h1
          font-size: 40px
      p .button
        font-size: 1em

@media screen and (max-width: 633px)
  .video-section
    .video-content
      div.title2 h1
        font-size: 38px
      p .button
        font-size: 0.9em

@media screen and (max-width: 383px)
  .video-section
    .video-content
      .title
        font-size: 23px
      div.title2 h1
        font-size: 30px
      a.button
        font-size: 15px
      p .button
        margin-top: 1rem
        font-size: 0.8em

@media screen and (max-width: 308px)
  .video-section
    .video-content
      .title
        font-size: 21px
      div.title2 h1
        font-size: 25px
      a.button
        font-size: 13px

@media screen and (max-width: 280px)
  .video-section
    .video-content
      .title
        font-size: 20px
      .subtitle
        font-size: 14px
      div.title2 h1
        font-size: 25px
      a.button
        font-size: 13px

/* iPad in portrait & landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)

/* iPad in landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)

/* iPad in portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)

/* Retina iPad in portrait & landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2)

/* Retina iPad in landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2)

/* Retina iPad in portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 2)

/* iPad 1 & 2 in portrait & landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1)

/* iPad 1 & 2 in landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1)

/* iPad 1 & 2 in portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 1)

/* iPad mini in portrait & landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1)

/* iPad mini in landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 1)

/* iPad mini in portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 1)

/* iPhone 5 in portrait & landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px)

/* iPhone 5 in landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape)

/* iPhone 5 in portrait */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait)

/* iPhone 2G-4S in portrait & landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px)

/* iPhone 2G-4S in landscape */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape)

/* iPhone 2G-4S in portrait */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait)
