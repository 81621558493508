.section-inner-page
  .bckg-left, .bckg-right
    opacity: 0.2
    z-index: 2
  .bckg-left
    background: transparent url(../../../assets/image/background/bckg-texture.png) top repeat-y
  .bckg-right
    background: transparent url(../../../assets/image/background/bckg-texture.png) top repeat-y
  .content
    min-height: 20vh
    ol
      li
        &::marker
          color: var(--orange-dark)
          font-weight: 700
          line-height: 45px
    strong
      color: var(--blue2)
      font-weight: 700
    .text-align-right
      text-align: right
      strong
        font-family: 'Marck Script', sans-serif
        line-height: 1
        font-size: calc(1.2vmin + 16px)
        font-weight: 400
        color: var(--black)
    .text-align-center
      text-align: center
    .text-align-left
      text-align: left
    .text-align-right
      text-align: right
    .text-align-justify
      text-align: justify
    audio:focus
      outline: none !important
    blockquote
      p
        padding-bottom: unset
    p
      padding-bottom: 10px
    h1
      padding-bottom: 2vh
      text-transform: uppercase
      opacity: 0.8
    h2, h3, h4
      padding-top: 1vh
      color: var(--red)

    blockquote, p, li, em
      font-size: calc(0.5vmin + 11px)
      text-align: justify
      font-weight: 400
      line-height: 1.6
    iframe
      max-width: 640px
      padding: 1vh 0
    img
      position: relative
      max-width: 640px
      height: auto
      width: 100%
      display: block
      margin-right: auto
      margin-left: auto
      padding: 1vh 0
  button
    border: none
    outline: none
    transition: opacity
    background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem

  h1.donate
    color: var(--red)
    opacity: 0.8

.donate-section
  img
    padding: 0

@media (max-width: 991px)
  .section-inner-page
    .bckg-left, .bckg-right
      display: none
@media (max-width: 767px)
  .section-inner-page
    .content
      padding-left: 1% !important
      padding-right: 1% !important
    img
      max-width: 100%
    iframe
      max-width: 100% !important
