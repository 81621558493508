.image-section
  width: 100%
  background-color: var(--blue)
  //overflow: hidden
  min-height: 350px !important
  -webkit-transition: -webkit-min-height 1s ease
  -webkit-transition: min-height 1s ease
  -o-transition: min-height 1s ease
  transition: min-height 1s ease
  .bg-img
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 0
    background-repeat: no-repeat
    background-size: cover
    background-position: center
  .bg-img.bg-overlay:before
    content: ""
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: rgba(45,151,204,0.875)
  .page-title
    position: absolute
    top: 45%
    left: 0
    padding: 0 1em
    display: block
    width: 100%
    text-align: center
    color: #fff
    line-height: 1.2
    font-weight: 700
    letter-spacing: 2px
    font-size: 2em
    z-index: 1
  .shows-over-fixed
    top: 350px !important
    position: absolute
    background-color: #fff
    height: auto
    width: 100%
    left: 0
    z-index: 5
    .down-angle-container
      position: relative
      pointer-events: none
      width: 100%
      overflow: hidden
      transform: translateY(-65%) !important
      z-index: 3
      top: 0
      .down-angle
        width: 0
        height: 0
        border-color: transparent
        border-bottom-color: #fff
        border-style: solid
      .down-angle-1
        border-right-width: 100vw
        float: left
        margin-left: -0.5em
      .down-angle-2
        border-left-width: 100vw
