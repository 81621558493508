.about-us
  position: relative
  .text-align-center
    text-align: center
  li
    padding-bottom: 15px
  h1
    padding-bottom: 2vh
    text-transform: uppercase
    text-align: center
  h2, h3, h4
    padding-top: 1vh
    color: var(--blue2)

  p, li, em
    font-size: calc(0.5vmin + 11px)
    text-align: justify
    font-weight: 400
    line-height: 1.6

  iframe
    max-width: 640px
    padding: 1vh 0
  img
    position: relative
    // max-width: 640px
    max-width: 100%
    height: auto
    width: auto
    display: block
    margin-right: auto
    margin-left: auto
    padding: 1vh 0
  button
    border: none
    outline: none
    transition: opacity
    background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem
  .content
    min-height: 20vh
    blockquote
      p
        padding-bottom: unset
        text-align: left
    p
      text-align: justify
      line-height: 1.5
      font-weight: 400
      padding-bottom: 10px
    .text-align-right
      text-align: right
      strong
        font-family: 'Marck Script', sans-serif
        line-height: 1
        font-size: calc(1.2vmin + 20px)
        font-weight: 400
        color: var(--blue-dark3)
    li
      list-style-type: none
    li:before
      content: ''
      position: absolute
      width: 8px
      height: 8px
      background: var(--blue4)
      border-radius: 50%
      border: 0
      margin: 8px -18px

@media (max-width: 991px)
  .about-us
    img.logo
      width: 110px
    .content
      padding-left: 1rem !important
      padding-right: 1rem !important

@media (max-width: 767px)
  .about-us img.logo
    width: 100px

@media (max-width: 414px)
  .about-us img.logo
    width: 80px

@media (max-width: 360px)
  .about-us img.logo
    width: 70px

@media (max-width: 320px)
  .about-us
    h1
      font-size: 1.1em
    img.logo
      width: 60px
    .content
      padding-left: 5px !important
      padding-right: 5px !important
