.civil-initiatives
  z-index: 2
  p
    line-height: 25px
    font-weight: 400
  .button-group
    button
      border: none
      outline: none
      transition: opacity
      background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem
  .content
    min-height: 20vh
    .label
      display: flex
      align-items: center
      justify-items: center
    .wrap-circle
      background-color: var(--blue)
      height: 90px
      width: 90px
      padding: 20px
      border-radius: 50%
    .done-icon
      color: green
      position: absolute
      font-size: 2.5em
      margin-top: -13px
  .MuiPaper-elevation4
    box-shadow: none
  .MuiAppBar-colorDefault
    background-color: transparent
  .MuiTab-textColorPrimary.Mui-selected
    color: var(--blue2)
  .MuiTabs-indicator
    background-color: var(--blue2)
  .MuiButton-containedPrimary
    background-color: var(--blue)
  .MuiButton-containedPrimary:hover
    background-color: var(--blue2)
  .Mui-selected .MuiSvgIcon-root
    fill: var(--orange)
  .MuiStepIcon-root.MuiStepIcon-completed, .MuiStepIcon-root.MuiStepIcon-active, .MuiFormLabel-root.Mui-focused
    color: var(--blue2)
  .MuiInput-underline:after
    border-bottom: 2px solid var(--blue2)

  form
    .MuiButton-containedPrimary
      transition: 0.25s
      color: #fff
      background-color: var(--blue2) !important
      &:hover
        background-color: var(--blue) !important

@media (max-width: 425px)
  .civil-initiatives 
    .MuiTabs-root
      margin: 25px 0
    .MuiBox-root
      margin: 0
      padding: 10px
    .content 
      button
        padding: 2px
      .label, p
        text-align: left
        font-size: 11px
      p 
        text-align: center
        line-height: 17px