.login
  width: 40px
  display: flex
  flex: 1 1 auto
  justify-content: center
  overflow: hidden
  .MuiLinearProgress-barColorPrimary
    background-color: var(--orange-dark)
  .MuiButton-containedPrimary
    background-color: var(--blue2)
  .MuiButton-containedPrimary:hover
    background-color: var(--orange-dark)
  button.btn-exit
    font-weight: 300
  button.btn-exit-google 
    div
      padding: 6px 6px 6px 16px !important
    span
      padding: 6px 16px 6px 0 !important
  .hide
    display: none
  .show
    display: block
  img.show
    width: 40px
    height: 40px
    border-radius: 50%
    border: 2px solid var(--white)
    object-fit: cover
  .wrap-login-block
    .login-block
      cursor: pointer
      display: flex
      justify-content: center
      svg
        width: 2rem
        height: 2rem
        color: var(--white)
        border-radius: 50%
        border: .125rem solid transparent
        transition: all .3s ease-in-out
      .MuiAlert-icon
        width: auto !important
        svg
          width: 2rem !important
          height: 2rem !important
    &:active,
    &:hover
      svg
        color: var(--orange)
        opacity: 0.8
    .welcome-block
      cursor: auto
      top: 100px
      //right: 0
      position: absolute
      //display: flex
      justify-content: center
      //max-width: 360px
      min-width: 225px
      border-radius: 0 0 8px 8px
      box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.15)
      background-color: var(--white)
      transition: all .3s ease-in-out
      opacity: 0
      visibility: hidden
      z-index: 2
      .text-block
        padding: 20px 15px
        flex-wrap: wrap
        justify-content: center
        align-items: center
        width: 354px
        h3
          font-size: 1.15rem
          opacity: 0.8
        p
          color: var(--blue-dark)
          line-height: 1.2
          font-size: 14px
          font-weight: 400
        p.Mui-error
          color: var(--red)
          font-size: 12px
        p.message
          font-size: 12px
        p:nth-child(2)
          font-weight: 500
          color: var(--orange-dark)
        hr
          border: 0
          background-color: var(--blue-dark3)
          background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), var(--blue-dark3), rgb(0, 0, 0, 0))
          background-color: var(--white)
        div
          width: 100%
          .icon
            color: "var(--orange-dark)"
            opacity: 0.85
            width: 2.3rem
        span.color-b
          color: var(--black)
        section.social-icons
          display: flex
          flex-wrap: wrap
          align-content: center
          justify-content: center
          width: 330px
          font-size: 35px
          text-align: center
          a, div
            display: inline-block
            position: relative
            float: left
            width: 50px
            height: 50px
            margin: 5px
            article
              border-radius: 50%
              display: flex
              width: 100%
              height: 100%
              background-color: var(--white)
              box-shadow: 0 0 6px -2px #000, 0 0 45px -20px #000
              align-content: center
              justify-content: center
              align-items: center
              transform: perspective(300px) rotateY(0deg)
              transition: all .5s ease-in-out
              cursor: pointer
              &:hover
                transform: perspective(300px) rotateY(180deg)
                transition: all .5s ease-in-out
                img
                  transform: scale(-1, 1)
                  transition: all .5s ease-in-out
              img
                transform: scale(1)
                transition: all .5s ease-in-out
                height: 25px
                width: auto
          .google
            &:hover article
              background-color: #fff
          .soc-vk
            article
              background-color: #4d76a1
          .soc-vk
            &:hover article
              background-color: #4d76a1
          .soc-ok
            article
              background-color: #ee8208
          .soc-ok
            &:hover article
              background-color: #ee8208
      .MuiFormControl-root
        width: 100%
        margin-bottom: 5px
      button
        margin-top: 5px
        width: 100%
        height: 40px
      .MuiButton-containedPrimary
        background-color: var(--blue2)
      .MuiButton-containedPrimary.btn-orange
        background-color: var(--orange-dark)
        opacity: 0.85
      .MuiFormLabel-root.Mui-focused
        color: var(--blue2)
      .MuiInput-underline:after
        border-bottom: 2px solid var(--blue2)
    &:active,
    &:hover
      .login-block svg
        opacity: 1
        width: 2.5rem
        height: 2.5rem
        border: .125rem solid var(--white)
      .welcome-block
        top: 70px
        opacity: 1
        visibility: visible
  .welcome-block-auth
    cursor: auto
    top: 100px
    position: absolute
    display: flex
    justify-content: center
    min-width: 225px
    border-radius: 0 0 8px 8px
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.15)
    background-color: var(--white)
    transition: all .3s ease-in-out
    opacity: 0
    visibility: hidden
    z-index: 2
    .text-block-auth
      padding: 20px 0
      display: flex
      flex-wrap: wrap
      justify-content: center
      width: 354px
      img.show
        width: 80px
        height: 80px
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        cursor: default !important
      .card-text
        line-height: 1.2
        padding-bottom: 10px
        border-bottom: 1px solid #e8eaed
        .user-name
          font-size: 16px
          color: var(--black)
        .user-mail
          font-size: 14px
          color: var(--gray-dark)
      .text-message
        line-height: 1
        font-weight: 400
        font-size: 15px
        color: var(--blue2)
      .text-message2
        color: var(--orange-dark)
        padding-bottom: 10px
        border-bottom: 1px solid #e8eaed
      .button-wrap
        padding-bottom: 10px
        border-bottom: 1px solid #e8eaed
      .group-text
        a
          webkit-transition: -webkit-all 0.25s
          transition: all 0.25s ease
          color: var(--gray-dark)
          &:hover
            color: var(--blue2)
        .MuiSvgIcon-root
          font-size: 1.2rem
        p
          line-height: 2.1
          font-size: 12px

  .MuiFilledInput-underline:after
    border-bottom: 2px solid var(--blue2)
  .MuiAlert-message
    display: flex
    align-items: center
    justify-content: flex-start
    .MuiAlertTitle-root
      margin: 0
      text-align: left

  &:active,
  &:hover
    .welcome-block-auth
      top: 70px
      opacity: 1
      visibility: visible

@media (max-width: 991px)
  .login
    .wrap-login-block.show
      margin: 20px 0
      .welcome-block
        max-width: unset
        section.social-icons
          width: unset
    img.show
      margin: 20px 0
  .flag
    margin-top: 10px
    margin-bottom: 20px
