.donate-block
  .MuiFormLabel-root.Mui-focused
    color: var(--blue2)
  p
    padding: 0
    text-align: left
    line-height: 0.9
    font-size: 14px
    .letter-1
      color: var(--orange)
    .letter-2
      color: var(--green)
    .letter-3
      color: var(--red)
  .btn-send
    padding: 5px 15px
    font-weight: 400
    background-color: var(--orange)
    color: var(--white)
    border: none
    &:hover
      background-color: var(--green-light)
      color: var(--black)
      border: none
  .MuiButton-outlined.offer-agreement
    background-color: var(--white)
    border: 2px solid var(--black)
    transition: all ease 0.15s 
    a
      color: var(--black)
    &:hover
      border-color: var(--orange)
      color: var(--black)
      a
        color: var(--blue2)
  .MuiInput-underline:after
    border-bottom: 2px solid var(--orange)
  .MuiButton-label a
    color: var(--black)
    svg
      color: var(--orange-dark)
      opacity: 0.9
  .group-text
    display: flex
    .amount 
      display: flex
      flex: 1 1 100px
      .rub
        align-self: center
    button
      flex: 1 1 50px
      transition: .15s ease-out
  form
    margin-top: -15px
  .donate-block__recurrent-block
    cursor: default
    border: none
    background-color: var(--green-light)
    margin-bottom: 5px
    border-radius: 5px
    box-shadow: 4px 3px 20px rgb(53 110 173 / 20%)
    transition: all 0.3s ease
    &:hover
      color: var(--white)
      background-color: var(--orange)
      .recurrent-block__icons
        svg 
          color: var(--white)
          opacity: 0.8
          transition: all 0.3s ease
    .recurrent-block__text
      cursor: pointer
      flex-grow: 1
      font-size: 14px
      text-transform: uppercase
    .recurrent-block__icons
      cursor: pointer
      .expanded[aria-expanded="false"]
        transform: rotate(0)
        transition: all 0.3s ease
      .expanded[aria-expanded="true"]
        transform: rotate(180deg)
        transition: all 0.3s ease
    svg
      color: var(--orange)
    .link
      color: var(--orange)
      font-weight: 500
    form
      display: flex
      flex-direction: column
      .amount-group
        display: flex
        .amount
          flex-grow: 1
        .rub
          align-self: center
      .MuiFormGroup-root
        flex-direction: row
        justify-content: space-between
      .MuiTypography-body1
        font-size: 14px
        line-height: 1.4
      .MuiFormControl-root
        margin-bottom: 10px
      p.error-status-text-show, .MuiFormHelperText-root.Mui-error
        color: #f44336
        font-size: 12px
      p.error-status-text-hide
        display: none
      .MuiInputBase-input
        min-width: 250px
      button
        background-color: var(--orange)
        max-width: 285px
        align-self: center
  .MuiCollapse-wrapperInner
    color: var(--black)
    background-color: var(--white)
  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline 
    border-color: var(--orange)



@media (max-width: 1024px)
  .donate-block
    padding-top: 15px
    form
      margin: unset
    .donate-block__recurrent-block
      margin-top: 25px

@media (max-width: 374px)
  .donate-block .donate-block__recurrent-block .recurrent-block__text, .donate-block .MuiButton-label a
    font-size: 13px
  .donate-block .donate-block__recurrent-block .MuiFormLabel-root
    min-width: 300px
  .MuiFormLabel-root.Mui-error
    font-size: 13px
  .donate-block .donate-block__recurrent-block form .MuiInputBase-input
    min-width: 230px
