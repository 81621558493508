.modal-open
  padding: 0 !important
  .MuiCardHeader-title
    color: var(--black)
  .MuiAvatar-colorDefault 
    background-color: var(--blue3)
    height: 60px
    width: 60px
  .MuiCardHeader-subheader
    font-size: calc(0.5vmin + 11px) !important
    margin-top: 5px
  .MuiInputLabel-outlined.MuiInputLabel-shrink
    margin-top: -10px
  .backdrop
    overflow: hidden
    .modal-feedback
      img, svg
        vertical-align: middle
        width: 80%
      button:nth-child(4)
        border-radius: 4px 4px 0 0
      button:nth-child(5)
        border-radius: 0 0 4px 4px
      .MuiFormControl-fullWidth
        margin-bottom: 1em
      .MuiInputLabel-outlined.MuiInputLabel-shrink
        transform: translate(14px, 0px) scale(0.75)
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline
        border-color: var(--blue) !important
      .MuiFormLabel-root.Mui-focused
        color: var(--blue) !important
        margin-top: -10px !important
      button.Mui-disabled
        color: #FFFFFF !important
      .MuiButton-containedPrimary
        color: #fff
        background-color: var(--blue3) !important
      .MuiPaper-elevation1
        box-shadow: none !important
      .MuiTypography-body2
        font-size: calc(1vmin + 12px)
        line-height: 1
      .MuiTypography-colorTextSecondary.MuiTypography-displayBlock
        font-size: 0.9rem !important
        padding-top: 10px

      .recaptcha-wrap
        display: -webkit-flex
        display: flex
        justify-content: center
        padding-top: 10px
        p
          color: red
          font-size: 0.75rem
      .MuiAlert-icon
        width: 30px


@media only screen and (max-width: 916px)
  .MuiTypography-body2
    font-size: calc(1.4vmin + 10px) !important

@media only screen and (max-width: 335px)
  .modal-body
    padding: 0 !important

@media only screen and (max-width: 300px)
  .makeStyles-avatar-8
    width: 55px !important
    height: 55px !important
  .backdrop .modal-feedback img, .backdrop .modal-feedback svg
    width: 80%
