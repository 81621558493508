.section-inner
  height: auto
  .avatar
    background-color: var(--blue)
  .media
    height: 175px
  .linearProgress
    height: 7px
    background-color: var(--blue-light2)
    border-radius: 10px
    margin: 0 16px
  .MuiLinearProgress-bar
    background-color: var(--blue-light)
  .MuiCardHeader-root
    padding: 16px 0 16px 16px
    height: 88px
  .MuiCardContent-root
    padding: 16px 16px 0 16px
  .MuiCardHeader-title
    line-height: 1
    font-size: 1.15rem
  .group-text
    display: flex
    color: rgba(0, 0, 0, 0.87)
    &:hover
      color: var(--blue-dark)
    .amount
      display: flex
      padding: 0 2px
      .MuiInput-root
        margin-bottom: 5px
        flex: 1 1 10px
      .MuiInput-underline:after
        border-bottom: 2px solid var(--orange)
    p
      line-height: 1
      font-size: 0.7em
      padding: 0
      margin: 10px 0 5px 0
      .letter-1
        color: var(--orange)
      .letter-2
        color: var(--green)
      .letter-3
        color: var(--red)
  .donate > button:nth-child(1)
    padding-right: 0
    margin-right: -10px
  .donate:hover
    .MuiIconButton-label
      color: var(--orange)
  .button
    background-color: var(--blue)
    font-size: 12px
    width: 100%
    border: 1px solid
    &:hover
      background-color: var(--blue2)
  .button.orange
    background-color: var(--orange)
    &:hover
      background-color: var(--orange-dark)
  strong
    color: var(--orange-dark)
  .card-actions
    justify-content: space-between
  .card-content-text
    overflow: hidden
    text-align: left
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 4 !important
  .expanded[aria-expanded="false"]
    transform: rotate(0)
    transition: all 0.3s ease
  .expanded[aria-expanded="true"]
    transform: rotate(180deg)
    transition: all 0.3s ease

  @media (max-width: 575px)
  .section-inner
    .MuiPaper-root
      max-width: 100%
