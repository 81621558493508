.footer
  position: relative
  display: flex
  align-items: center
  color: var(--white)
  border-top: solid 4px var(--blue-light)
  background: linear-gradient(15deg, var(--blue-dark), var(--blue-dark3))
  z-index: 2
  li
    list-style-type: none
  a, .post
    color: white
    webkit-transition: -webkit-all 0.25s
    -webkit-transition: all 0.25s ease
    -o-transition: all 0.25s ease
    transition: all 0.25s ease
    &:hover
      color: #f1c74f
  .section-top
    top: 0
    width: 100%
    padding-top: 15px
    padding-bottom: 55px
    .block-social-icons
      display: inline-flex
      align-items: center
      justify-items: center
      .icon-social
        display: block
        margin-right: 15px
    .hr-style1
      border: 0
      height: 1px
      background-image: -webkit-linear-gradient(left, transparent, rgb(255 255 255), transparent)
      margin-bottom: 1.5em
    .group-text
      h6
        font-weight: 500
        font-size: 1.08rem
      .hr-style2
        width: 60px
        height: 2px
        background-color: var(--blue-light)
        opacity: 0.6
        margin-top: 5px
        margin-bottom: 10px
      p
        font-size: 14px
        line-height: 1.9rem

      .MuiSvgIcon-root
        width: 0.8em

  .section-bottom
    bottom: 0
    margin: 0
    width: 100%
    padding: 10px 0
    position: absolute
    background-color: var(--blue-dark3)
    .wrapper
      span
        font-size: 0.8rem
        display: block
        color: var(--gray-yellow)
        line-height: 1.5
      a
        font-size: 0.85rem
        font-weight: 500
        margin-bottom: -1px

@media (max-width: 1199px)
  .footer .section-top .group-text p
    font-size: 13px
    line-height: 2


@media (max-width: 991px)
  .footer .section-top
    .block-social-icons
      margin-top: 10px
      margin-bottom: 0
    .hr-style1
      margin-top: 5px
    .section-top .group-text p
      padding-bottom: 15px
      line-height: 1.3

@media (max-width: 767px)
  .footer .section-top
    padding-top: 20px !important
    .block-social-icons
      margin-top: 15px
      margin-bottom: 0
    .group-text
      text-align: center
      margin-bottom: 25px !important
      h6
        font-size: 1.1em
        margin: 0
      p
        padding-bottom: 0
        font-size: 14px
        line-height: 1.7
      .hr-style2
        display: inline-block
        text-align: center
        margin: 0

@media (max-width: 575px)
  .footer .section-top
    text-align: center !important

@media (max-width: 359px)
  .footer .section-top .block-buttons span
    display: block
    width: 100% !important
    margin-bottom: 5px
