.section-news
  position: relative
  z-index: 2
  background-color: var(--white)
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.35)
  h1
    color: var(--blue)
    opacity: 0.9
    text-transform: uppercase
    letter-spacing: 0.1em
    img
      width: 50px !important
      margin-right: 20px
  font-family: 'Comfortaa', sans-serif
  padding-top: 10vh
  small a
    color: var(--blue-dark)
  .solutions-box
    -webkit-transition: .5s
    transition: .5s
    margin-bottom: 30px
    h3
      font-family: 'Comfortaa', sans-serif
      font-weight: bold
      font-size: 17.5px
      margin: 0 0 10px 0
      a
        color: var(--blue-dark)
        &:hover
          color: var(--blue2)
    .blog-image
      border-radius: 10px
      overflow: hidden
      img
        webkit-transition: -webkit-all 0.5s
        -webkit-transition: all 0.5s ease
        -o-transition: all 0.5s ease
        transition: all 0.5s ease
        opacity: 0.75
        &:hover
          opacity: 1
          transform: scale(1.08)
    .learn-more-btn
      display: contents
      color: var(--blue-dark)
      font-size: 14.5px
      font-weight: 700
      &:hover
        color: var(--blue2)
      svg
        width: 20px
        position: relative
        top: -1px
        margin-right: 3px
        margin-left: 0
        overflow: hidden
        span
          color: var(--bs-gray-dark)

  .MuiPagination-root
    .MuiPagination-ul
      justify-content: center
      width: 100%

    .MuiPaginationItem-textPrimary.Mui-selected
      background-color: var(--blue)
      opacity: 0.8

@media (max-width: 375px)
  .section-news
    .row
      div
        width: 100%
