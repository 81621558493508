.section-about-us
  // overflow: hidden
  position: relative
  min-height: 40vh
  z-index: 2
  margin-bottom: 4em
  .image-container
    min-height: 40vh
    max-height: 55vh
    display: flex
    align-items: center
    margin-bottom: 20px
    .wrap-img
      .img
        display: block
        // box-shadow: -3px 4px 26px 0px rgba(34, 60, 80, 0.17)

  .content-container
    .content
      position: relative
      overflow: hidden
      padding: 0.85em 0 2em 2em
      width: 95%
      h2
        padding: 0
        font-size: 1.5625em
        line-height: 1.25
        font-weight: 500
        font-family: 'Montserrat', sans-serif
        color: var(--blue)
        strong
          font-size: 1.5em
      p
        margin: 0 0 1.25em 0
      .button
        margin-bottom: 0
        position: absolute
        right: 0
        bottom: -100%
      .button.in-view
        -webkit-animation: button_grow 0.5s ease-out 1s forwards
        animation: button_grow 0.5s ease-out 1s forwards
      .button.arrow:hover:after
        left: calc(100% - 2em)
        opacity: 1
      a.button.arrow:after
        content: ""
        display: block
        position: absolute
        left: calc(100% - 3em)
        opacity: 0
        top: 50%
        height: 9px
        width: 9px
        -webkit-transition: -webkit-all 0.25s ease-out
        -webkit-transition: all 0.25s ease-out
        -o-transition: all 0.25s ease-out
        transition: all 0.25s ease-out
        -ms-transform: translateY(-50%) rotate(-45deg)
        -webkit-transform: translateY(-50%) rotate(-45deg)
        transform: translateY(-50%) rotate(-45deg)
        border-right: solid 2px #fff
        border-bottom: solid 2px #fff
    .content:before
      content: ""
      position: absolute
      top: 0
      left: 0
      background-color: var(--blue)
      height: 0
      width: 2px
    .content:after
      content: ""
      position: absolute
      bottom: 0
      left: 0
      background-color: var(--blue)
      height: 2px
      width: 0
    .content.in-view:before
      -webkit-animation: line_grow_heigth 0.25s linear 0.5s forwards
      animation: line_grow_height 0.25s linear 0.5s forwards
    .content.in-view:after
      -webkit-animation: line_grow_width 0.5s linear 0.75s forwards
      animation: line_grow_width 0.5s linear 0.75s forwards

  .image-container
    .laptop
      margin: 25% 0 0 -20%
      width: 100%
    .phone-container
      margin: 0 0 -50% 50%
      width: 28%
      transform: translate3d(0, -200px, 0px) rotate(-15deg)
      img.phone
        width: 100%
        position: relative
        z-index: 3
        margin: 0
        overflow: hidden
      .design-container
        position: absolute
        z-index: 2
        top: 6%
        left: 9%
        height: 91%
        width: 91%
        -webkit-transform: perspective(380px) rotateY(9deg)
        transform: perspective(380px) rotateY(9deg)
        .design
          background-size: 100% auto
          background-position: 0
          position: absolute
          top: 0
          left: 0
          height: 100%
          width: 100%
          -webkit-animation: phone_design 35s ease-out 1s infinite
          animation: phone_design 35s ease-out 1s infinite
          z-index: 1

@keyframes phone_design
  0%
    background-position: 0 0
  95%
    background-position: 0 100%
  100%
    background-position: 0 0

@keyframes line_grow_width
  0%
    width: 0
  100%
    width: 100%

@keyframes line_grow_height
  0%
    height: 0
  100%
    height: 100%

@keyframes button_grow
  0%
    bottom: -100%
  100%
    bottom: 0

@media screen and (max-width: 1399px)
  .section-about-us
    .image-container
      .laptop
        margin: 35% 0 0 -20%
      .phone-container
        margin: 10% 0 -50% 50%

@media (max-width: 1199px)
  .section-about-us .container
    max-width: unset !important

@media (max-width: 1134px)
  .section-about-us
    .image-container
      .laptop
        margin: 45% 0 0 -20%
      .phone-container
        margin: 10% 0 -50% 50%

@media (max-width: 1067px)
  .section-about-us .content-container .content
    padding: 1em 0 2em 1.5em
    p
      font-weight: 400
      line-height: 1.8
  .section-about-us
    .image-container
      .laptop
        margin: 45% 0 0 -20%
      .phone-container
        margin: 20% 0 -50% 50%

@media (max-width: 1024px)
  .section-about-us .content-container
    padding-left: 0
  .section-about-us .content-container .content
    width: unset

@media (max-width: 981px)
  .section-about-us .content-container .content h2
    font-size: 1.4em
  .section-about-us
    .image-container
      .laptop
        margin: 45% 0 0 -20%
      .phone-container
        margin: 30% 0 -50% 50%

@media (max-width: 926px)
  .section-about-us .content-container .content p
    font-size: 0.9em
  .section-about-us .image-container .phone-container
    margin: 25% 0 -50% 50%
    transform: translate3d(0px, -150px, 0px) rotate(-15deg)

@media (max-width: 866px)
  .section-about-us
    .image-container
      .laptop
        margin: 60% 0 0 -20%
      .phone-container
        margin: 30% 0 -50% 50%

@media (max-width: 767px)
  .section-about-us
    min-height: 89vh !important
    .container
      max-width: 100% !important
  .section-about-us
    .content-container, .image-container
      width: 100% !important
    .content-container
      .content.in-view:after, .content.in-view:before
        animation: none
      .content
        text-align: center
        padding: 0 2em
        margin-bottom: 0
        .button
          position: unset
  .section-about-us
    .image-container
      .laptop-wrapper
        transform: translateY(-102.4px) translateZ(0px) !important
        .laptop
          width: 77%
          margin: 20% 0 0 -5%
      .phone-wrapper
        transform: translateY(calc(-45vmin - 24px)) translateZ(0px) !important
        .phone-container
          width: 20%
          margin: 5% 0 0 60%

@media (max-width: 640px)
  .section-about-us .image-container
    margin-top: 4vh
    max-height: 45vh
  .section-about-us .image-container .phone-wrapper
    transform: translateY(calc(-40vmin - 24px)) translateZ(0px) !important

@media (max-width: 535px)
  .section-about-us
    .image-container
      max-height: 0
    .content-container
      padding: 0
      .content
        padding: 10px

@media (max-width: 480px)
  .section-about-us
    .image-container
      min-height: 30vh

@media (max-width: 430px)
  .section-about-us
    .content-container .content h2
      strong
        font-size: 1.2em
      .subtitle
        margin-top: 10px
    .image-container
      min-height: 30vh

@media (max-width: 400px)
  .section-about-us
    .image-container
      min-height: 25vh

@media (max-width: 360px)
  .section-about-us
    .image-container
      margin-top: 10vh
      min-height: 30vh

@media (max-width: 360px)
  .section-about-us
    .image-container
      margin-top: 15vh

@media (max-width: 300px)
  .section-about-us
    .image-container
      min-height: 20vh
