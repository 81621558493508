.projects
  z-index: 2
  button
    border: none
    outline: none
    transition: opacity
    background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem
  .content
    min-height: 20vh
  .MuiPagination-root
    display: flex
    justify-content: center
  .MuiPaginationItem-textPrimary.Mui-selected, .MuiPaginationItem-textPrimary.Mui-selected:hover, .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible
    background-color: var(--blue2)
