.donate-section
  h2
    font-size: 1.3em
    color: var(--blue2)
  hr
    border: 0
    height: 3px !important
    margin: 5px 0 10px 0
    background-color: var(--orange)
    opacity: 0.9
    width: 80px
  ul
    margin: 0
    padding-left: 0
    li.name-fund
      line-height: 1
      span
        font-size: 16px
    li
      font-size: 14.5px
      line-height: 1.8
      font-weight: 300
      list-style-type: none
      svg
        margin: 0 5px 0 -3px
        font-size: 20px
      span
        font-weight: 600
        font-size: 14px

@media (max-width: 1024px)
  .donate-section ul li
    line-height: 1.66

@media (max-width: 991px)
  .bank-details
    margin-top: 2vh
