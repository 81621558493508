header.show-nav
  .site-header-inner
    background-color: var(--blue) !important
    -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.5)
    box-shadow: 0 0 30px rgba(0,0,0,0.5)
header
  .site-header-inner
    position: fixed
    width: 100%
    background-color: transparent !important
    transition: all 0.25s ease
    z-index: 10
    nav
      position: relative
      z-index: 8
      .navbar
        padding: 0
        .navbar-toggler
          margin-top: -5px
          color: var(--white)
          border-color: var(--white)
          z-index: 9
          &:focus
            box-shadow: none
        .custom-toggler .navbar-toggler-icon
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important
        .navbar-brand
          color: var(--white) !important
          flex: 1 1 20px
          font-family: 'Marck Script', cursive
          div
            flex-basis: auto
            a
              color: var(--white)
              font-size: 1.3em
              font-weight: 500
              display: flex
              align-items: center
              img.logo
                width: 50px
                margin-right: 10px
        .navbar-collapse
          .navbar-nav
            width: 100%
            align-items: center
            .inner-dropdown
              min-height: 70px
              width: 40px
              position: relative
              display: flex
              -webkit-box-orient: horizontal
              -webkit-box-direction: normal
              flex-flow: row wrap
              flex: 1 1 auto
              max-width: 100%
            .nav-item.flag
              width: 30px
              flex: max-content
              text-align: right
              padding-right: 0
            .nav-item
              flex: 1 1 20px
              text-align: center
              color: var(--white)
              padding: 12px 12px
              line-height: 1.4
              &:hover, &:active, a.dropdown-toggle:hover, a.dropdown-toggle:active
                transition: all 0.5s ease
                color: var(--blue-dark)
              a.dropdown-toggle
                color: var(--white)
                padding: 0
            .dropdown
              display: flex
              flex: 1 1 auto
              align-items: center
              justify-content: center
              margin-bottom: 0
              padding: 0.5em 0
              line-height: 1.4
              color: #fff
              a[aria-expanded="true"]
                transition: all 0.5s ease
                color: var(--blue-dark)
              .dropdown-menu
                display: block
                opacity: 0
                overflow: visible
                position: absolute
                top: 100%
                width: 300px
                left: calc(50% - 150px)
                background-color: rgba(7,70,95,0.9)
                transform: scaleX(0) scaleY(0.75)
                transform-origin: center top
                transition: all ease 0.3s 
                border-radius: 0 !important
                border: 0 !important
                margin-top: 0 !important
                animation: 0.5s slideup
                &:before
                  content: ""
                  position: absolute
                  z-index: 99
                  top: -10px
                  width: 0
                  height: 0
                  left: calc(50% - 10px)
                  border-style: solid
                  border-width: 0 10px 10px
                  border-color: transparent transparent rgba(7,70,95,0.9)
                a
                  display: block
                  font-size: 0.9em
                  font-weight: 500
                  color: var(--white)
                  text-align: center
                  padding: 15px 0
                  transition: background-color 0.8s ease
                  &:hover, &:active, &:visited
                    background-color: rgba(0,0,0,0.4)
                a:first-child
                  margin-top: -8px
                a:last-child
                  margin-bottom: -8px
              .dropdown-toggle::after
                display: none
              .dropdown-menu.show
                opacity: 1
                transform: scaleX(1) scaleY(1)
            img
              cursor: pointer
              opacity: 0.9
.nav-top.show-nav
  visibility: inherit !important

.nav-top
  visibility: hidden
  position: relative
  -webkit-box-orient: horizontal
  -webkit-box-direction: normal
  flex-flow: row nowrap
  display: -ms-flexbox
  display: -webkit-box
  display: flex
  -ms-flex-pack: end
  -webkit-box-pack: end
  justify-content: flex-end
  width: 100%
  max-width: 100%
  z-index: 2
  svg
    margin-left: 5px
  .inner
    display: flex
    background-color: var(--orange)
    transition: background-color 0.35s ease
    &:hover
      background-color: var(--orange-dark)
    a.cta-form-btn
      color: var(--white)
      margin: 0
      font-size: 0.7em
      padding: 7px 15px
      text-transform: uppercase

@media only screen and (max-width: 1199px)
  nav .navbar
    font-size: 0.85em
    line-height: 1.25
    .navbar-brand
      font-size: 1.22em
      a img.logo
        width: 40px
  nav .navbar .navbar-collapse .navbar-nav .nav-item
    padding: 5px 5px

@media only screen and (max-width: 991px)
  header .site-header-inner nav .navbar .navbar-collapse .navbar-nav
    img.flag
      transition: all 0.25s ease
      transform: scale(1)
      margin: 22px 0 22px 0
    img.flag:hover
      transition: all 0.25s ease
      transform: scale(1.3)
    .inner-dropdown
      min-height: unset
      margin: 0
      padding: 0
      width: unset
  .nav-item, .nav-item.dropdown
    padding: 15px 0 !important
    font-size: 1.2em
    .dropdown-toggle::after
      display: inline-block !important
  .dropdown-item
    z-index: 11 !important
  header .site-header-inner nav .navbar .navbar-brand
    font-size: 1.4em
    div
      margin-left: 0 !important
  header.hide-nav .site-header-inner
    .navbar-collapse.collapse.show
      display: none
  header.show-nav .site-header-inner
    padding-bottom: 1vh
    height: auto
  header .site-header-inner nav .navbar .navbar-collapse .navbar-nav .nav-item.dropdown
    display: block
    height: 100%
    .dropdown-menu
      transition: all 0.25s ease
      transform: scaleX(0) scaleY(0)
    .dropdown-menu.show
      position: relative
      margin-top: 10px !important
      opacity: 1
      transform: scaleX(1) scaleY(1)

@media only screen and (max-width: 399px)
  nav .navbar .navbar-brand  div a img.logo
    width: 50px

@media only screen and (max-width: 365px)
  nav .navbar .navbar-brand
    font-size: 1.3em !important
    div a img.logo
      width: 45px !important
  .navbar-toggler
    margin-top: -10px
    padding: 0.2rem 0.35rem !important
    font-size: 1.1rem !important

@media only screen and (max-width: 326px)
  nav .navbar .navbar-brand
    div a img.logo
      width: 40px !important

@media only screen and (max-width: 310px)
  nav .navbar .navbar-brand
    font-size: 1.05em !important


@keyframes slideup 
  from 
    opacity: 0
    transform: scaleX(0) scaleY(0.75)
    transform-origin: center top
    transition: all ease 0.3s 
  to 
    transform: scaleX(1) scaleY(1)
    opacity: 1
    transition: all ease 0.3s
  
