.cookie-consent
  bottom: 0
  position: fixed
  width: 100%
  line-height: 1.2
  color: var(--white)
  padding: 0 25px
  background-color: var(--blue-dark3)
  z-index: 12
  span
    font-size: 13px
    font-weight: 300
  span.button
    padding: 5px 25px
    font-size: 0.8em
  span:nth-child(1) > strong
    font-weight: 600
  svg
    top: -4px
    right: -5px
    position: absolute
    display: block
    transform: rotate(45deg)
    cursor: pointer
