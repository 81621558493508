@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap')
@import url('https://fonts.googleapis.com/icon?family=Material+Icons')
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css')

\:root
  --white: #fff
  --black: #2d2d2d
  --blue: #2d97CC
  --blue2: #006ba3
  --blue3: #4c9ee5
  --blue4: #23759e
  --blue-light: #87cbe5
  --blue-light2: #edf3f5
  --blue-dark: #07465f
  --blue-dark2: #59b7
  --blue-dark3: #00354b
  --blue-dark4: #00566a
  --blue-dark5: #006677
  --gray: #ccc
  --gray-light: #cccccc78
  --gray-yellow: #cec5b7
  --gray-dark: #5f6368
  --green: #42a818
  --green-light: #c8f3bb
  --orange: #f87d3f
  --orange-dark: #f65809
  --yellow: #f1c74f
  --yellow2: #da2
  --yellow-dark: #c49c0f
  --red: #dc3545

html
  overflow-x: hidden
  height: 100%
html::-webkit-scrollbar 
  display: none


body
  color: var(--black)
  margin: 0
  padding: 0
  background: #fff
  font-family: 'Montserrat', sans-serif
  font-weight: 500

blockquote
  overflow: hidden
  padding: 12px
  margin-left: 0
  margin-right: 0
  font-style: italic
  border-left: 7px solid var(--blue)
  background-color: var(--gray-light)
  border-bottom-right-radius: 15px
  border-top-right-radius: 15px
  font-size: calc(0.5vmin + 11px)
  p
    text-align: left

audio, canvas, img, video
  max-width: 100%
  vertical-align: middle

a
  text-decoration: none!important
  outline: 0!important

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1
  font-family: 'Montserrat', sans-serif
  font-weight: 600
  line-height: 1.5
  padding-top: 0.5rem

h1
  font-size: calc(2vmin + 13px)
h2
  font-size: calc(1vmin + 15px)
h3
  font-size: calc(0.5vmin + 15px)
h4
  font-size: calc(0.2vmin + 15px)

h1
  margin: 0
  padding: 0
  line-height: 1.1
  font-weight: 700
  color: var(--blue2)
  font-family: 'Montserrat', sans-serif

p
  font-family: 'Montserrat', sans-serif
  color: #2d2d2d
  padding: 0
  margin: 0
  line-height: 2

ol, ul
  padding-left: 1rem

.button
  display: inline-block
  margin: 0
  padding: 1em 4em
  background-color: var(--blue)
  color: var(--white)!important
  line-height: 1.1
  vertical-align: middle
  font-size: 0.85em
  font-weight: 500!important
  font-family: inherit
  text-align: center
  text-decoration: none!important
  border: none
  -webkit-appearance: none
  outline: none
  cursor: pointer
  transition: background-color 0.35s ease
  text-transform: uppercase

.button.white
  background-color: var(--white)
  color: var(--blue) !important
.button.white:hover
  background-color: var(--blue4)
  color: var(--white) !important

.button:focus, .button:hover
  color: var(--white)
  background-color: var(--blue-dark)

.small, small
  font-size: 80%
  font-weight: 400

.txt-center
  text-align: center


