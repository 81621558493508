.documents
  z-index: 2
  width: 100%
  button
    border: none
    outline: none
    transition: opacity
    background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem
  .content
    min-height: 20vh
  .MuiButton-containedSecondary
    color: #fff
    background-color: var(--blue)
    justify-content: center
    outline: none
    &:hover
      background-color: var(--blue2)
  .MuiButton-startIcon
    margin: 0
    

  .MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellWithRenderer, .MuiDataGrid-root .MuiDataGrid-cellLeft.MuiDataGrid-cellEditing
    justify-content: center
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer
    &:last-child
      justify-content: center
  .MuiDataGrid-root .MuiDataGrid-columnHeaderMoving
    background-color: transparent
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus
    outline: none
  .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within
    outline: none
  .MuiDataGrid-root
    .MuiDataGrid-columnHeaderMoving
      border-right: none
