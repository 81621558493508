.modal-content
  .modal-recurrent-info
    padding: 1.5rem
    .wrap-block
      display: flex
      flex-direction: column
      align-items: center
      .title
        padding-bottom: 20px
        font-size: calc(1vmin + 23px)
      .text
        line-height: 1.3
        font-weight: 400
        font-size: calc(0.8vmin + 10px)
        padding-bottom: 18px
      .text-small
        line-height: 1.3
        font-weight: 400
        font-size: 13px
      .red
        color: darkred
      .picture
        text-align: center
        padding: 0 18px 18px 18px

      .letter-1
        color: var(--orange)
      .letter-2
        color: var(--green)
      .letter-3
        color: var(--red)
      .MuiButton-containedPrimary
        color: #fff
        background-color: var(--orange-dark)

@media (max-width: 430px)
  .modal-content .modal-recurrent-info
    padding: 1rem
    .wrap-block .title 
      font-size: calc(1vmin + 21px)

@media (max-width: 375px)
  .modal-content 
    padding: 10px
    .modal-recurrent-info
      padding: 10px
      .wrap-block .title 
        text-align: center
        font-size: calc(1vmin + 19px)