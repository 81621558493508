.section-activities:before
  content: ""
  position: absolute
  z-index: 9
  bottom: -45px
  width: 0
  height: 0
  border-style: solid
  border-width: 0 60px 50px 0
  border-color: transparent var(--blue-dark) transparent transparent
  left: calc(50% - 60px)
.section-activities:after
  content: ""
  position: absolute
  z-index: 9
  bottom: -45px
  width: 0
  height: 0
  border-style: solid
  border-width: 50px 60px 0 0
  border-color: var(--blue-dark4) transparent transparent transparent
  left: 50%
.section-activities
  position: relative
  -webkit-box-shadow: inset 0 0 40px rgba(0,0,0,0.35)
  box-shadow: inset 0 0 40px rgba(0,0,0,0.35)
  background-color: var(--blue-dark)
  webkit-transition: -webkit-all 0.5s ease-out 0.25s
  -webkit-transition: all 0.5s ease-out 0.25s
  -o-transition: all 0.5s ease-out 0.25s
  transition: all 0.5s ease-out 0.25s
  opacity: 1
  -ms-transform: scale(1)
  -webkit-transform: scale(1)
  transform: scale(1)
  z-index: 3
  .link
    display: block
    white-space: nowrap
    position: absolute
    opacity: 1
    bottom: 2em
    -webkit-transition: -webkit-all 0.5s ease-out 0.25s
    -webkit-transition: all 0.5s ease-out 0.25s
    -o-transition: all 0.5s ease-out 0.25s
    transition: all 0.5s ease-out 0.25s
    .button
      padding: 1em 2.4em
    .button.color-f
      background-color: var(--blue-light)
      &:hover
        background-color: var(--blue-dark2)
    .button.color-d
      background-color: var(--orange)
      &:hover
        background-color: var(--orange-dark)
    .button.color-c
      background-color: var(--yellow)
      &:hover
        background-color: var(--yellow-dark)
  .flex-group
    display: -ms-flexbox
    display: -webkit-box
    display: flex
    flex-flow: row wrap
    max-width: 100%
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    .flex-inner
      display: flex
      flex: 1 1 100%
      max-width: 100%
      .subgroup:first-of-type
        background-color: var(--blue-dark3)
        .content h4:after
          background-color:  var(--blue-light)
        &:hover
          -ms-transform: translateX(-5px) scale(1.025)
          -webkit-transform: translateX(-5px) scale(1.025)
          transform: translateX(-5px) scale(1.025)
      .subgroup:nth-of-type(2)
        background-color: var(--blue-dark)
        &:hover
          -ms-transform: translateX(-5px) scale(1.025)
          -webkit-transform: translateX(-5px) scale(1.025)
          transform: translateX(-5px) scale(1.025)
        .content h4:after
          background-color: #F87D3F
      .subgroup:nth-of-type(3)
        background-color: var(--blue-dark4)
        &:hover
          -webkit-transform: translateX(5px) scale(1.025)
          transform: translateX(5px) scale(1.025)
        .content h4:after
          background-color: #2d97CC
      .subgroup:nth-of-type(4)
        background-color: var(--blue-dark5)
        &:hover
          -ms-transform: translateX(5px) scale(1.025)
          -webkit-transform: translateX(5px) scale(1.025)
          transform: translateX(5px) scale(1.025)
        .content h4:after
          background-color: var(--yellow)
      .subgroup
        display: flex
        align-items: center
        justify-content: center
        -webkit-transition: -webkit-all 0.2s ease
        -webkit-transition: all 0.2s ease
        -o-transition: all 0.2s ease
        transition: all 0.2s ease
        z-index: 1
        overflow: hidden
        max-width: 100%
        -ms-flex: 1 1 auto
        flex: 1 1 auto
        &:hover
          z-index: 2
          -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.2)
          box-shadow: 0 0 10px rgba(0,0,0,0.2)
          h4:after
            left: calc(50% - 60px) !important
            width: 120px !important
          .svg-icon
            -ms-transform: scale(1.25)
            -webkit-transform: scale(1.25)
            transform: scale(1.25)
        .content
          //padding: 2em 2em 6em 2em
          padding: 2em 1.8em 6em 1.8em
          display: block
          max-width: 325px
          margin: 0 auto
          opacity: 1
          -webkit-transition: -webkit-all 0.5s ease-out 0.5s
          -webkit-transition: all 0.5s ease-out 0.5s
          -o-transition: all 0.5s ease-out 0.5s
          transition: all 0.5s ease-out 0.5s
          -ms-transform: scale(1)
          -webkit-transform: scale(1)
          transform: scale(1)
          .svg-icon
            width: 60px
            height: 50px!important
            margin-bottom: 1em
            -webkit-transition: -webkit-transform 0.25s ease-out
            transition: -webkit-transform 0.25s ease-out
            -o-transition: transform 0.25s ease-out
            transition: transform 0.25s ease-out,-webkit-transform 0.25s ease-out
          h4
            font-weight: 700
            position: relative
            font-size: 1em
            margin-bottom: 0.75em
            padding-bottom: 1.1em
            color: var(--white)
            display: flex
            align-items: center
            height: 50px
            justify-content: center
          h4:after
            content: ""
            display: block
            position: absolute
            bottom: 0
            left: calc(50% - 30px)
            width: 60px
            height: 2px
            -webkit-transition: -webkit-all 0.25s ease
            -webkit-transition: all 0.25s ease
            -o-transition: all 0.25s ease
            transition: all 0.25s ease
          p
            display: inline-block
            font-weight: 400
            font-size: 0.9em
            line-height: 2
            color: var(--white)
            text-align: center
            margin: 0
          .item-text
            min-height: 145px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 5

@media (max-width: 979px)
  .section-activities
    &:before, &:after
      display: none
    .flex-group
      .flex-inner
        flex-flow: row wrap
        .subgroup .content
          padding: 2em
          display: block
          p
            display: none
          h4
            margin: 0
            padding: 0
          h4:after
            display: none
        .subgroup
          -webkit-box-flex: 2 !important
          max-width: 100%
          flex: 1 1 auto
          &:hover
            transform: scale(1.025) !important
      .link
        display: none
