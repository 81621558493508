.targeted-assistance
  z-index: 2
  button
    border: none
    outline: none
    transition: opacity
    background-color: transparent
  .MuiSvgIcon-colorPrimary
    color: var(--blue2)
    font-size: 2rem
  .content
    min-height: 20vh
