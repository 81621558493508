.section-our-team
  overflow: hidden
  position: relative
  -webkit-transition: -webkit-opacity 1s ease 0s !important
  -webkit-transition: opacity 1s ease 0s !important
  -o-transition: opacity 1s ease 0s !important
  transition: opacity 1s ease 0s !important
  opacity: 1
  z-index: 2
  &:after
    content: ""
    background-color: rgba(45,151,204,0.85)
    display: block
    position: absolute
    z-index: 1
    top: 0
    left: 0
    width: 100%
    height: 100%
  &:before
    content: ""
    position: absolute
    z-index: 2
    top: 0
    left: 0
    width: 0
    height: 0
    border-style: solid
    -ms-transform: scale(1)
    -webkit-transform: scale(1)
    transform: scale(1)
    -webkit-transform-origin: top center
    transform-origin: top center
    border-width: 14vw 50vw 0 50vw
    border-color: #fff transparent transparent transparent
  .content-section--wrapper
    width: 100%
    position: relative
    z-index: 5
    text-align: center
    padding-top: calc(15% + 5em)
    padding-bottom: 3em
    margin: 0
    .box-lines
      position: relative
      display: inline-block
      &:before
        content: ""
        position: absolute
        bottom: 0
        left: 0
        width: 2px
        background-color: var(--white)
      &:after
        content: ""
        position: absolute
        top: 0
        right: 0
        height: 0
        width: 2px
        background-color: var(--white)
    .box-lines.in-view
      &:before
        -webkit-animation: line_grow_height_left 0.25s linear 0.75s forwards
        animation: line_grow_height_left 0.25s linear 0.75s forwards
      &:after
        -webkit-animation: line_grow_height_right 0.25s linear 1.5s forwards
        animation: line_grow_height_right 0.25s linear 1.5s forwards
      .box-top-line
        position: absolute
        display: block
        top: 0
        left: 0
        height: 2px
        width: 0
        background-color: var(--white)
      .box-top-line.in-view
        -webkit-animation: line_grow_width 0.45s linear 1s forwards
        animation: line_grow_width 0.45s linear 1s forwards
    h3
      font-size: 2em
      display: inline-block
      padding: 1em 2em
      color: #fff
      font-weight: 700
      line-height: 2
      margin: 0
      span
        display: block
  .move-up
    position: absolute
    z-index: 6 !important
    top: 0
    width: 100%

.section-our-team.image-section
  min-height: 0
  position: relative

.image-section
  position: relative
  width: 100%
  background-color: #2d97CC
  .bg-img
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 0
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    background-image: url(../../../../assets/image/office-miloserdie.jpg)

.section-our-team
  .down-angle-container
    pointer-events: none
    -webkit-transition: -webkit-transform 1s ease 1s
    transition: -webkit-transform 1s ease 1s
    -o-transition: transform 1s ease 1s
    transition: transform 1s ease 1s,-webkit-transform 1s ease 1s
    top: 100%
    border-bottom-width: 30vw
    z-index: 6
    overflow: visible
    width: 100%
    position: absolute
    -ms-transform: translateY(-55%)
    -webkit-transform: translateY(-55%)
    transform: translateY(-55%)
    .down-angle
      width: 0
      height: 0
      border-color: transparent
      border-bottom-width: 30vw
      border-bottom-color: #fff
      border-style: solid
    .down-angle-1
      border-right-width: 100vw
      float: left
      margin-left: -0.5em
    .down-angle-2
      border-left-width: 100vw

  .container-item
    z-index: 3
    padding: 20px 0
  .image-item
    opacity: 0.8
    margin: 0 1em
    transform: scale(1)
    -webkit-transition: transform ease 0.4s, opacity 1s ease 0s !important
    transition: transform ease 0.4s, opacity 1s ease 0s !important
    -o-transition: transform ease 0.4s, opacity 1s ease 0s !important
    &:hover
      opacity: 1
      -webkit-transition: transform ease 0.4s, opacity 1s ease 0s !important
      transition: transform ease 0.4s, opacity 1s ease 0s !important
      -o-transition: transform ease 0.4s, opacity 1s ease 0s !important
      transform: scale(1.1)
  .button-wrap
    margin-top: 5em
    margin-bottom: 10em
    .button
      position: relative
      z-index: 6


@keyframes line_grow_height_left
  0%
    height: 0
  100%
    height: 100%

@keyframes line_grow_width
  0%
    width: 0
  100%
    width: 100%

@keyframes line_grow_height_right
  0%
    height: 0
  100%
    height: 100%

@media (max-width: 767px)
  .section-our-team .content-section--wrapper h3 span
    font-size: calc(2vmin + 10px)

@media (max-width: 380px)
  .section-our-team .content-section--wrapper h3
    padding: 1em 0
    span
      padding-left: 3em
      padding-right: 3em

@media (max-width: 345px)
  .section-our-team .content-section--wrapper h3 span
    padding-left: 2em
    padding-right: 2em

@media (max-width: 392px)
  .section-our-team .content-section--wrapper h3 span
    padding-left: 1em
    padding-right: 1em
