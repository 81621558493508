.shows-over-fixed
  position: relative
  background-color: var(--white)
  width: 100%
  left: 0
  -webkit-transition: -webkit-top 0.75s cubic-bezier(0.165,0.84,0.44,1) 0.25s
  -webkit-transition: top 0.75s cubic-bezier(0.165,0.84,0.44,1) 0.25s
  -o-transition: top 0.75s cubic-bezier(0.165,0.84,0.44,1) 0.25s
  transition: top 0.75s cubic-bezier(0.165,0.84,0.44,1) 0.25s
  z-index: 5

.shows-over-fixed .down-angle-container
  pointer-events: none
  width: 100%
  overflow: hidden
  position: absolute
  z-index: 3
  top: 0
  -ms-transform: translateY(-70%)
  -webkit-transform: translateY(-70%)
  transform: translateY(-70%)

.shows-over-fixed .down-angle-container
  .down-angle
    width: 0
    height: 0
    border-color: transparent transparent #fff
    border-style: solid
  .down-angle-1
    border-right-width: 100vw
    float: left
    margin-left: -.5em
  .down-angle-2
    border-left-width: 100vw
