.scrolling-text
  display: block
  position: relative
  margin-top: -15vh
  margin-bottom: 5vh
  padding: 1em 0
  -webkit-transition: -webkit-opacity 2.5s ease 1s
  -webkit-transition: opacity 2.5s ease 1s
  -o-transition: opacity 2.5s ease 1s
  transition: opacity 2.5s ease 1s
  z-index: 2
  overflow: hidden
  h2
    -ms-transform: translateX(50%)
    -webkit-transform: translateX(50%)
    transform: translateX(50%)
    -webkit-animation: scrolling_text 15s linear 0s infinite
    animation: scrolling_text 15s linear 0s infinite
    -webkit-animation-duration: 30s
    animation-duration: 30s
    color: var(--gray-yellow)
    display: inline-block
    font-size: calc(10vmin + 24px)
    font-weight: 700
    margin: 0
    white-space: nowrap
    opacity: 0.5
    span
      display: inline-block
      width: 6%
      height: 10px

@keyframes scrolling_text
  0%
    -ms-transform: translateX(5%)
    -webkit-transform: translateX(5%)
    transform: translateX(5%)
  100%
    -ms-transform: translateX(-51%)
    -webkit-transform: translateX(-51%)
    transform: translateX(-51%)

@media only screen and (max-width: 1240px)
  .scrolling-text
    margin-top: -13vh

@media only screen and (max-width: 1080px)
  .scrolling-text
    margin-top: -10vh

@media only screen and (max-width: 767px)
  .scrolling-text
    margin-top: -8vh

@media only screen and (max-width: 515px)
  .scrolling-text
    margin-top: -5vh
