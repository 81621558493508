.section-contacts
  position: relative
  margin-top: 2vh
  z-index: 2
  .ymaps-2-1-79-copyright
    display: none
  .wrap-maps
    position: relative
    width: 100%
    height: 340.5px
  svg
    fill: var(--blue)
    margin-right: 5px
  .title-text
    font-size: 1em
    font-weight: 500 !important
    line-height: 1.15
  .group-text
    margin-top: 10px
    font-weight: 400
    span
      display: block
